import { FormControl, FormControlLabel } from "@mui/material";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import DOMPurify from "dompurify";
import { convertFromHTML, convertToRaw, EditorState } from "draft-js";
import ContentState from "draft-js/lib/ContentState";
import draftToHtml from "draftjs-to-html";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-multi-lang";
import { Colors } from "../../../../../Utils/Colors";
import { FaqType, FullPurpleTextField, PurpleSwitch } from "../../../../../Utils/Global";
import { createErrorNotification, notifyError, notifySuccess } from "../../../../../Utils/Notification";
import HttpApi from "../../../../../httpApi";
import { getLanguages } from "../../../../../lang/translations";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import EbloomTooltip from "../../../../Core/Modules/Popovers-Tooltips/EbloomTooltip";

const FormControlStyles = styled(FormControl)(({theme}) => ({
    '& .MuiFormLabel-root.Mui-focused':{
        color:Colors.EBLOOM_PURPLE
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
        borderColor:Colors.EBLOOM_PURPLE
    },
    "& .MuiFormHelperText-root":{
        color:Colors.EBLOOM_CONTRAST_BLUE
    },
    '& label.Mui-focused': {
        color: Colors.EBLOOM_PURPLE
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: "white",
        '&.Mui-focused fieldset': {
            borderColor: Colors.EBLOOM_PURPLE,
        },
        '&:hover fieldset': {
            borderColor: Colors.EBLOOM_PURPLE,
        },
    },
    "& .MuiFormLabel-root":{
        color:Colors.EBLOOM_PURPLE
    },
    "& .MuiOutlinedInput-root fieldset":{
        borderColor:Colors.EBLOOM_PURPLE
    },
    "& .MuiInputBase-input":{
        color:Colors.EBLOOM_PURPLE
    },
    "& .MuiSelect-iconOutlined":{
        color:Colors.EBLOOM_PURPLE
    }
}))

const EditFaq = (props) => {

    //PROPS
   const {handleClose,listEdit,onCreate} = props

    //INIT IMPORTANT CONST

    const t = useTranslation()
    const langList = getLanguages().filter(el => el.value !== 'nl').sort((a, b) => a.value.localeCompare(b.value)).reverse()
    langList.push(getLanguages()[2])

    //USE STATES

    const [faq, setFaq] = useState([])
    const [type, setType] = useState('');
    const [actualFaq, setActualFaq] = useState({question:'',answer:''});

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [step, setStep] = useState(0);
    const [skippedNL, setSkippedNL] = useState(true);
    const [langValidator, setLangValidator] = useState('fr');
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [priority, setPriority] = useState(false);
    const [packagesSelected, setPackagesSelected] = useState([]);

    const packages = {
        "free" : 1,
        "starter" : 2,
        "pro" : 4
    }

    //HANDLERS

    const handlePackages = (event) => {
        const selected = event.target.value
        setPackagesSelected(selected)
    }

    const onEditorStateChange = editorState => {
        setEditorState(editorState);
        const rawContent = convertToRaw(editorState.getCurrentContent());
        if(step===2 && actualFaq.question.trim() !=='' && editorState.getCurrentContent().getPlainText().trim() !== ''){
            setSkippedNL(false)
        }
        setActualFaq(prevState => {
            const textConverted = draftToHtml(rawContent)
            let faqEdit = {...prevState};
            faqEdit.answer = textConverted;
            return faqEdit;
        })
    };

    const handleType = (event) => {
        setType( event.target.value)
        setFaq(prevState => {
            prevState.forEach(faqEdit => faqEdit.type = event.target.value)
            return prevState
        })
    }
    const handleInputQuestion = (event) => {
        if(step===2 && event.target.value.trim() !== '' && editorState.getCurrentContent().getPlainText().trim() !== ''){
            setSkippedNL(false)
        }
        setActualFaq(prevState => {
            let faqEdit = {...prevState};
            faqEdit.question = event.target.value;
            return faqEdit;
        })
    };

    const handleAddQuestion = () => {

        if(skippedNL){
            setFaq(prevState => {
                prevState.pop()
                return prevState
            })
        }

        const tagsList = tags.filter(el => selectedTags.includes(el.id));

        let packageCode = 0

        packagesSelected.forEach(el => packageCode += el)

        if(onCreate){
            HttpApi.post("/faq/create", [faq,tagsList,priority,packageCode] ).then(() => {
                notifySuccess("New Faq added !");
                handleClose()
            }).catch(error => {
                notifyError(error);
            })
        }else{
            HttpApi.post("/faq/update", [faq,tagsList,packageCode] ).then(() => {
                notifySuccess("Faq updated !");
                handleClose()
            }).catch(error => {
                notifyError(error);
            })
        }

    }

    const handleLang = (event) => {
        const selectedLang = event.target.value
        setLangValidator(selectedLang)
        if(selectedLang === 'fr'){
            setActualFaq(prevState => {
                let faqEdit = {...prevState}
                faqEdit.question = faq[0].question
                faqEdit.answer = faq[0].answer
                return faqEdit
            })
            convertHtmlToText(faq[0].answer)

        }else if(selectedLang === 'en') {
            setActualFaq(prevState => {
                let faqEdit = {...prevState}
                faqEdit.question = faq[1].question
                faqEdit.answer = faq[1].answer
                return faqEdit
            })
            convertHtmlToText(faq[1].answer)

        }else if(selectedLang === 'nl'){
            setActualFaq(prevState => {
                let faqEdit = {...prevState}
                faqEdit.question = faq[2].question
                faqEdit.answer = faq[2].answer
                return faqEdit
            })
            convertHtmlToText(faq[2].answer)
        }

    }


    const handlePillClick = (index) => {

        if (index !== step){
            if (index < step && step !==3) {
            saveChanges(index)
            }else if(index === step+1 && step <2) {
                if ((!editorState.getCurrentContent().hasText() || editorState.getCurrentContent().getPlainText().trim().length === 0 || actualFaq.question.trim() === '' || type === '' || selectedTags.length === 0)) {
                    createErrorNotification('Error completing form', 'Fill all fields before continuing')
                }else{
                    saveChanges(index)
                }
            }else if (step === 2) {
                if (!skippedNL) {
                    if ((!editorState.getCurrentContent().hasText() || editorState.getCurrentContent().getPlainText().trim().length === 0 || actualFaq.question.trim() === '' || type === '' || selectedTags.length === 0)) {
                        createErrorNotification('Error completing form', 'Fill all fields before continuing or use the switcher to skip nederlands')
                    } else {
                        setFaq(prevState => {
                            prevState[step].answer = actualFaq.answer
                            prevState[step].question = actualFaq.question
                            prevState[step].lang = langList[step].value
                            prevState[step].type = type
                            return prevState
                        })
                        if (checkValidFaq()) {
                            saveChanges(index)
                        }
                    }
                } else {
                    if (checkValidFaq()) {
                        saveChanges(index)
                    }
                }
            }else if (step === 3) {
                setStep(index)
                setActualFaq(prevState => {
                    let faqEdit = {...prevState}
                    faqEdit.question = faq[index].question
                    faqEdit.answer = faq[index].answer
                    return faqEdit
                })
                convertHtmlToText(faq[index].answer)
            } else if (index === 3 ) {
                setFaq(prevState => {
                    prevState[step].answer = actualFaq.answer
                    prevState[step].question = actualFaq.question
                    prevState[step].lang = langList[step].value
                    prevState[step].type = type
                    return prevState
                })
                if (checkValidFaq()) {
                    saveChanges(index)
                } else {
                    createErrorNotification('Error completing form', 'Fill all questions before validate')
                }
            }else{
                createErrorNotification('Error completing form', 'Fill the questions in between what you have selected to continue there ')
            }
        }
    }

    const handleNextStep = () => {
        if(step !== 3){
            if(step === 2){
                if(!skippedNL){
                    if ((!editorState.getCurrentContent().hasText() || editorState.getCurrentContent().getPlainText().trim().length === 0 || actualFaq.question.trim() === '' || type === '' || selectedTags.length === 0 || packagesSelected.length === 0)){
                        createErrorNotification('Error completing form', 'Fill all fields before continuing or use the switcher to skip nederlands')
                    }else{
                        if(checkValidFaq())
                            changeActualFAQ()
                        else
                            createErrorNotification('Error completing form', 'Fill all questions to access this section')
                    }
                }else {
                    if(checkValidFaq())
                        changeActualFAQ()
                    else
                        createErrorNotification('Error completing form', 'Fill all questions to access this section')
                }
            }else{
                if (!editorState.getCurrentContent().hasText() || editorState.getCurrentContent().getPlainText().trim().length === 0 || actualFaq.question.trim() === '' || type === '' || selectedTags.length === 0 || packagesSelected.length === 0){
                    createErrorNotification('Error completing form', 'Fill all fields before continuing')
                }else{
                    changeActualFAQ()
                }
            }
        }else{

            changeActualFAQ()
        }
    }



    const handlePreviousStep = () =>{
        const newStep = step-1
        if(step !==3){
            setFaq(prevState => {
                prevState[step].answer = actualFaq.answer
                prevState[step].question = actualFaq.question
                prevState[step].lang = langList[step].value
                prevState[step].type = type
                return prevState
            })
        }


        setStep(newStep)

        setActualFaq(prevState => {
            let faqEdit = {...prevState}
            faqEdit.question = faq[newStep].question
            faqEdit.answer = faq[newStep].answer
            return faqEdit
        })
        convertHtmlToText(faq[newStep].answer)
    }

    const handleSkip = (event) => {
        setSkippedNL(event.target.checked)
    }
    const handlePriority = (event) => {
        const value = event.target.checked
        setPriority(value)
        setFaq(prevState => {
            prevState.forEach(faq => faq.priority = value)
            return prevState
        })
    }

    const handleTags = (event) => {
        const tagsSelected = event.target.value;
        setSelectedTags(tagsSelected)

    }
    //OTHER FUNCTIONS

    const convertHtmlToText = (html) => {
        let blocksFromHtml
        if(html === null){
            blocksFromHtml = convertFromHTML('<p></p>');
        }else{
            blocksFromHtml = convertFromHTML(html);
        }
        const state = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks,blocksFromHtml.entityMap)
        setEditorState(EditorState.createWithContent(state))
    }


    const calculateBackground = (index) => {

        let classes = "";
        if(index === 0){
            classes += "identity_step_first ";
        }else if(index === 3){
            classes += "identity_step_last ";
        }
        if(index === step){
            return classes + "identity_step_selected";
        }

        return classes + "identity_step_empty";
    }

    const changeActualFAQ = () => {
        setFaq(prevState => {
            prevState[step].answer = actualFaq.answer
            prevState[step].question = actualFaq.question
            prevState[step].lang = langList[step].value
            prevState[step].type = type
            return prevState
        })
        const newStep = step+1
        setStep(newStep)

        if(newStep===3){
            setActualFaq(prevState => {
                let faqEdit = {...prevState}
                faqEdit.question = faq[0].question
                faqEdit.answer = faq[0].answer
                return faqEdit
            })
            setLangValidator('fr')
            convertHtmlToText(faq[0].answer)
        }else{
            setActualFaq(prevState => {
                let faqEdit = {...prevState}
                faqEdit.question = faq[newStep].question
                faqEdit.answer = faq[newStep].answer
                return faqEdit
            })
            convertHtmlToText(faq[newStep].answer)
        }

    }

    const fetchTags = () => {
        HttpApi.get('/faq/getAllTags').then(response => {
            const data = response.data
            setTags(data)
        })
    }

    const initCreateOrEditStates = () => {
        if (onCreate) {
            setFaq([{question: '', answer: '', type: '', lang: langList[0].value, priority: false},
                {question: '', answer: '', type: '', lang: langList[1].value, priority: false},
                {question: '', answer: '', type: '', lang: langList[2].value, priority: false}]
            );
            setActualFaq({question: '', answer: '',});
            setType('');
            setPriority(false)
        } else {
            const listOrdered = listEdit.filter(el => el.lang !== 'nl').sort((a, b) => a.lang.localeCompare(b.lang)).reverse()
            const faqNL = {
                id_question: null,
                id: listEdit[0].id,
                type: type,
                question: '',
                answer: '<p></p>',
                lang: 'nl',
            }
            listEdit.length > 2 ?  listOrdered.push(listEdit.find(el => el.lang === 'nl')) : listOrdered.push(faqNL)

            setPriority(listEdit[0].priority)
            setFaq([...listOrdered])
            setActualFaq({question: listOrdered[0].question, answer: listOrdered[0].answer,})
            setType(listOrdered[0].type);
            setSelectedTags(listOrdered[0].tags.map(el => el.id));
            convertPackageCode(listOrdered[0].package_code)
            convertHtmlToText(listOrdered[0].answer)

        }
    }

    const convertPackageCode = (code) => {
        switch (code) {
            case 1:
                setPackagesSelected([1])
                break
            case 2:
                setPackagesSelected([2])
                break
            case 3:
                setPackagesSelected([1,2])
                break
            case 4:
                setPackagesSelected([4])
                break
            case 5:
                setPackagesSelected([1,4])
                break
            case 6:
                setPackagesSelected([2,4])
                break
            case 7:
                setPackagesSelected([1,2,4])
                break;
            default:
                break;
        }
    }
    
    const checkValidFaq = () => {
        let result;
        if(skippedNL){
            result = faq.filter(el => el.lang !== 'nl').every(item => {
                let tempDivElement = document.createElement("div");
                tempDivElement.innerHTML = DOMPurify.sanitize(item.answer);
                let text = tempDivElement.innerText.trim() || "";
                return !(text.length === 0 || item.question.trim() === '' || item.answer === '<p></p>\n' || type === '' || selectedTags.length === 0);
            })
        }else{
            result =  faq.every(item => {
                let tempDivElement = document.createElement("div");
                tempDivElement.innerHTML = DOMPurify.sanitize(item.answer);
                let text = tempDivElement.textContent || tempDivElement.innerText || "";
                return !(text.length === 0 || item.question.trim() === '' || item.answer === '<p></p>\n'|| type === '' || selectedTags.length === 0);
            })
        }
        return result
    }
    const saveChanges = (index) => {
        if(index !== 3){
            setFaq(prevState => {
                prevState[step].answer = actualFaq.answer
                prevState[step].question = actualFaq.question
                prevState[step].lang = langList[step].value
                prevState[step].type = type
                return prevState
            })
        }
        setStep(index)
        setLangValidator('fr')
        if(index === 3){
            setActualFaq(prevState => {
                let faqEdit = {...prevState}
                faqEdit.question = faq[0].question
                faqEdit.answer = faq[0].answer
                return faqEdit
            })
            convertHtmlToText(faq[0].answer)
        }else{
            setActualFaq(prevState => {
                let faqEdit = {...prevState}
                faqEdit.question = faq[index].question
                faqEdit.answer = faq[index].answer
                return faqEdit
            })
            convertHtmlToText(faq[index].answer)
        }


    }
    //UseEffect
    useEffect(() => {
        initCreateOrEditStates()
        fetchTags()
    }, []);



    //RENDERING
    return (
        <CardDefault title={onCreate ? _.upperFirst(t("backoffice.faq.createFaq")): _.upperFirst(t("backoffice.faq.updateFaq"))} showButton={true}  rightButtonPurple={false} rightButtonVariant={'secondary'} buttonText={_.upperFirst(t("core.close"))} handleRightButton={handleClose}>
            <Row >
                <Col className={'flex justify-center mb-30'}>
                    {
                        langList.map((lang, index) => (
                            <div key={index} className={"center inline_icon cursor-pointer ebloom-text ebloom-very-small-text " + calculateBackground(index)} onClick={() => handlePillClick(index)}>{index+1+'.' + lang.name}</div>
                        ))
                    }
                    <div key={3} className={"center inline_icon cursor-pointer ebloom-text ebloom-very-small-text " + calculateBackground(3)} onClick={() => handlePillClick(3)}>{_.upperFirst(t("admin.identity.validation"))}</div>
                </Col>
            </Row>
            {
                step===3 ?
                    <Row>
                        <Col md={3}>
                            <FullPurpleTextField required fullWidth select variant={"outlined"} size={"small"} label={_.upperFirst(t("backoffice.faq.lang"))} name={"lang-selector"}  onChange={handleLang} value={langValidator} >
                                {
                                    langList.map((lang, index) => {
                                        if(!skippedNL){
                                            return <MenuItem key={index} value={lang.value}>{lang.name}</MenuItem>
                                        }else{
                                            if(lang.value !== "nl")
                                                return <MenuItem key={index} value={lang.value}>{lang.name}</MenuItem>
                                        }
                                        return null;
                                    })
                                }
                            </FullPurpleTextField>
                        </Col>

                        <Col md={3}>
                            <FullPurpleTextField fullWidth  variant={"outlined"} size={"small"} label={_.upperFirst(t("backoffice.faq.type"))} name={"type-selector"}  value={type} />
                        </Col>
                        <Col md={4}>
                            <FullPurpleTextField fullWidth  variant={"outlined"} size={"small"} label={'Tags'} name={"tags-selector"}  value={tags.filter(el => selectedTags.includes(el.id)).map(item => _.upperFirst(t("admin.faq.tags."+ _.toLower(_.deburr(item.label)))) + ' ')} />
                        </Col>
                        <Col md={4} className={'mt-20'}>
                            <FullPurpleTextField fullWidth  variant={"outlined"} size={"small"} label={'Packages'} name={"packages-selector"}  value={Object.entries(packages).filter(el => packagesSelected.includes(el[1])).map(item => _.upperFirst(t("backoffice.faq.packages."+ _.toLower(_.deburr(item[0])))) + ' ')} />
                        </Col>
                        <Col className={'mt-10'} md={12}>
                            <FullPurpleTextField fullWidth  label={_.upperFirst(t("backoffice.faq.question"))} value={actualFaq.question} name={'question'} />
                        </Col>

                        <Col className={'mt-10'} md={12}>
                            <Editor
                                readOnly={true}
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                placeholder={_.upperFirst(t("backoffice.faq.answerPlaceholder"))}
                                toolbarHidden={step===3}
                            />
                        </Col>

                    </Row>


                    : <Row>

                        <Col md={3}>
                            <FullPurpleTextField required fullWidth select variant={"outlined"} size={"small"} label={_.upperFirst(t("backoffice.faq.type"))} name={"type-selector"}  onChange={handleType} value={type} >
                                {
                                    Object.values(FaqType).map((item, index) => <MenuItem key={index} value={item}>{t("admin.faq.types."+_.camelCase(item))}</MenuItem>)
                                }
                            </FullPurpleTextField>
                        </Col>
                        <Col md={4}>
                            <FormControlStyles required variant={"outlined"} fullWidth size={"small"}>
                                <InputLabel id={"tags-multiple-chip-label"}>{_.upperFirst(t("backoffice.faq.tags"))}</InputLabel>
                                <Select
                                    label={_.upperFirst(t("backoffice.faq.tags"))}
                                    labelId={"tags-multiple-chip-label"}
                                    multiple
                                    value={selectedTags}
                                    onChange={handleTags}
                                    renderValue={(selected) => (
                                        <div className={"flex flex-wrap"}>
                                            {selected.map((value, index) => (
                                                <Chip className={"m-2"} key={index} label={tags && tags.find(el => el.id === value) ? _.upperFirst(t("admin.faq.tags."+ _.toLower(_.deburr(tags.find(el => el.id === value).label)))) : null} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {
                                        tags.map((item,index)=> {
                                            return (
                                                <MenuItem selected={selectedTags.filter(el => el === item.id).length > 0} key={index} value={item.id}>{_.upperFirst(t("admin.faq.tags."+_.toLower(_.deburr(item.label))))}</MenuItem>

                                            )
                                        }
                                        )

                                    }
                                </Select>
                            </FormControlStyles>
                        </Col>
                        <Col md={4}>
                            <FormControlStyles required variant={"outlined"} fullWidth size={"small"}>
                                <InputLabel id={"packages-multiple-chip-label"}>{_.upperFirst(t("backoffice.faq.packages.packages"))}</InputLabel>
                                <Select
                                    label={_.upperFirst(t("backoffice.faq.packages.packages"))}
                                    labelId={"packages-multiple-chip-label"}
                                    multiple
                                    value={packagesSelected}
                                    onChange={handlePackages}
                                    renderValue={(selected) => (
                                        <div className={"flex flex-wrap"}>
                                            {selected.map((value, index) => (
                                                <Chip className={"m-2"} key={index} label={Object.entries(packages).find(el => el[1] === value) && _.upperFirst(t("backoffice.faq.packages."+ _.toLower(_.deburr(Object.entries(packages).find(el => el[1] === value)[0]))))} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {
                                        Object.entries(packages).map((item,index) => {
                                            return <MenuItem selected={packagesSelected.filter(el => el === item[0]).length > 0} key={index} value={item[1]}>{_.upperFirst(t("backoffice.faq.packages."+_.toLower(_.deburr(item[0]))))}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControlStyles>
                        </Col>
                        <Col >
                            <div className={'float-right'}>
                                <EbloomTooltip text={_.upperFirst(t("backoffice.faq.infoLinkHover"))+'\n'+_.upperFirst(t("backoffice.faq.infoLinkExampleExt"))+'\n'+_.upperFirst(t("backoffice.faq.infoLinkExampleInt"))}>
                                    <div className={"info_icon very_small_smile"}/>
                                </EbloomTooltip>
                            </div>

                        </Col>
                        <Col className={'mt-10'} md={12}>
                            <FullPurpleTextField  fullWidth required label={_.upperFirst(t("backoffice.faq.question"))} value={actualFaq.question} name={'question'} onChange={handleInputQuestion}/>
                        </Col>

                        <Col className={'mt-10'} md={12}>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                                placeholder={_.upperFirst(t("backoffice.faq.answerPlaceholder"))}
                                toolbar={
                                    {
                                        options : [
                                            'inline',
                                            'list',
                                            'emoji',
                                            'link'
                                        ],
                                        inline : {
                                            inDropdown: false,
                                            options: ['bold', 'underline'],
                                        },
                                        list: {
                                            inDropdown: false,
                                            options: ['unordered', 'ordered', 'indent', 'outdent'],
                                        },
                                        link : {
                                            defaultTargetOption: '_blank',
                                        }
                                    }
                                }
                            />
                        </Col>
                    </Row>
            }

            <Row className={'m-10 flex justify-between'}>
                <Col md={2}>
                    {
                        step !== 0 &&
                            <Row className={'float-left'}>
                                <Button size="sm" className="ebloom-btn-purple" onClick={handlePreviousStep}>{_.upperFirst(t("core.previous"))}</Button>
                            </Row>

                    }
                </Col>
                    {
                        step===2 &&
                            <Col>
                                <Row className={'float-right'}>
                                    <Col>
                                        <div>
                                            <FormControlLabel control={<PurpleSwitch checked={skippedNL} onChange={handleSkip} />} labelPlacement={"end"} label={_.upperFirst(t("backoffice.faq.skipNL"))} />
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                    }
                {
                    step===3 &&
                    <Col>
                        <div className={'flex justify-end'}>
                            <FormControlLabel control={<PurpleSwitch checked={priority} onChange={handlePriority} />} labelPlacement={"end"} label={_.upperFirst(t("backoffice.faq.setPriority"))} />
                        </div>
                    </Col>
                }
                <Col md={2}>
                    <Row className={'float-right'}>
                        <Col >
                            {step===3 ? <Button size="sm" className={"ebloom-btn-purple "} disabled={!checkValidFaq()}  onClick={handleAddQuestion}>{_.capitalize(t("backoffice.faq.add"))}</Button>
                                :<Button size="sm" className="ebloom-btn-purple" onClick={() => handleNextStep()}>{_.upperFirst(t("core.next"))}</Button>
                            }
                        </Col>

                    </Row>
                </Col>
            </Row>
        </CardDefault>
    )
}

EditFaq.propTypes ={
    handleClose : PropTypes.func.isRequired,
    onCreate : PropTypes.bool.isRequired,
    listEdit : PropTypes.array,

}
export default EditFaq